

$(document).ready(function() {

    $(".center").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 651,
                settings: "unslick"
            }
        ]

    });
});


/*   Slider Owl Carousel 2   */

$(function() {
    var owl = $('.owl-carousel'),
        owlOptions = {

            items: 1,
            singleItems: true,
            nav: false,
            dots: true,
            loop: true,
            startPosition: 0
        };


    /*  отключение слайдера при разрешении меньше 650px    */

    if ( $(window).width() > 650 ) {
        var owlActive = owl.owlCarousel(owlOptions);
    } else {
        owl.addClass('off');
    }

    $(window).resize(function() {
        if ( $(window).width() > 650 ) {
            if ( $('.owl-carousel').hasClass('off') ) {
                var owlActive = owl.owlCarousel(owlOptions);
                owl.removeClass('off');
            }
        } else {
            if ( !$('.owl-carousel').hasClass('off') ) {
                owl.addClass('off').trigger('destroy.owl.carousel');
                owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
            }
        }
    });



       /*  переключение слайдов по наведению на dots   */

    $(".owl-dot").mouseenter(function(){
        $(this).click()
    });
});



$(document).ready(function () {
    $('.popup_link').magnificPopup({
        type: 'inline',
        focus: '#name',
        closeOnBgClick: true
    });
});




$('[data-fancybox="images"]').fancybox({
    buttons : [
        'share',
        'thumbs',
        'close'
    ]

});


$(document).ready(function () {
    $('.popup_link').magnificPopup({
        type: 'inline',
        focus: '#name',
        closeOnBgClick: true
    });
});

